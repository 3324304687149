import React, { useEffect, useRef } from "react"
import { graphql, Link, navigate } from "gatsby"
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faCheck, faFileUpload, faKeyboard, faSdCard } from "@fortawesome/free-solid-svg-icons"
import cullAiIcon from "../images/cullai-icon.svg"

import Layout from "./../components/layout"
import { Hero, Section, SectionText, SectionImage, SectionTextHeading, SectionTextSubtitle, LearnMore } from "./../components/section"
import { Video } from "./../components/video"
import { FeatureCard } from "./../components/featurecard"
import { ProductReview } from "./../components/productreview"
import { AppStoreButton } from "./../components/appstorebutton"
import { FaqList, FaqListItem } from "./../components/ghost/faqs"
import { enhanceArticle, enhanceBlogArticle } from "./help/cullai"
import { ArticleFeed, ArticleFeedItem } from "../components/ghost/articlefeed"
import SEO from "../components/SEO/SEO"
import Banner from "../components/banner"

// markup
const CullAiProductPage = ({ data, location }) => {

  const { faqs, updates } = data

  const { faqarticles } = faqs
  faqarticles.forEach(enhanceArticle)

  const { updatearticles } = updates

  const roadmapContainer = useRef(null);

  useEffect(() => {
    window.TrelloBoards && window.TrelloBoards.load(roadmapContainer.current, { allAnchors: false })
    window.TrelloCards && window.TrelloCards.load(roadmapContainer.current, { compact: false, allAnchors: false })
  }, [roadmapContainer])

  return (
    <Layout>

      <SEO
        title="CullAi - Simple photo culling app"
        pathname={location.pathname}
        desc="Rejects low quality photos, Groups similar photos and selects best ones from each group. Detects important people and considers it while selecting keepers."
      />

      <Helmet>
        <script src="https://p.trellocdn.com/embed.min.js"></script>
      </Helmet>

      <div className="sticky top-0 py-2 bg-opacity-95 z-10 bg-gray-50 text-gray-500 text-base font-normal shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-row justify-between items-center">
          <nav>
            <Link className="font-bold text-xl text-gray-800 flex flex-row items-center" to="/cullai">
              <img className="flex-none w-8 h-8 -mt-1 rounded-lg object-cover" src={cullAiIcon} alt="CullAi Icon" title="CullAi Icon with letter C and AI in between"/>
              <div className="ml-2">CullAi</div>
            </Link>
          </nav>
          <nav className="space-x-4 text-sm sm:text-base">
            <Link className="text-gray-600" to="/cullai#features">Features</Link>
            <Link className="text-gray-600" to="/cullai#pricing">Pricing</Link>
            <Link className="text-gray-600" to="/cullai#updates">Updates</Link>
            <Link className="text-gray-600" to="/cullai#roadmap">Roadmap</Link>
          </nav>
        </div>
      </div>

      <Banner topClass="top-11" message="CullAi is now legacy, explore the fastest culling app - OptiCull" />

      <Hero>

        <SectionText>
          <h1 className="text-center text-4xl md:text-5xl lg:text-6xl font-bold">
            Simple photo culling app
          </h1>
          <SectionTextSubtitle className="text-center text-gray-500">
            Rejects low quality photos, Groups similar photos and selects best ones from each group.
          </SectionTextSubtitle>
          <AppStoreButton className="mt-3" 
            alt="Download CullAi on Apple App Store button" title="Download CullAi on Apple App Store button" 
            product="cullai" location="product_page_hero" 
            href="https://apps.apple.com/app/cullai/id1534000261?mt=12&amp;itsct=apps_box&amp;itscg=30200"
          >
          </AppStoreButton>
        </SectionText>

        <SectionImage>
          <StaticImage src="../images/cullai-hero.png" 
            alt="Hero image of CullAi - Photo culling app" title="Hero image of CullAi - Photo culling app showing app screens on different macs"
            blurredOptions={{ width: 256 }}>
          </StaticImage>
        </SectionImage>

      </Hero>

      <Section id="features" alternate>

        <SectionText className="md:order-1 md:pr-8">
          <SectionTextHeading>Auto Rates Photos by Face Quality</SectionTextHeading>
          <SectionTextSubtitle className="text-gray-500">Based on Bad lighting conditions, Out of Focus primary subjects, Camera shake, and Closed eyes.</SectionTextSubtitle>
          <SectionTextSubtitle className="text-gray-500">Uses Configurable mapping from Ai score to Ratings.</SectionTextSubtitle>
          <LearnMore to="/help/cullai/user-guide/auto-rating"></LearnMore>
        </SectionText>

        <SectionImage className="md:order-2">
          <Video src="p/cullai/auto-ratings.mp4"></Video>
        </SectionImage>

      </Section>

      <Section>

        <SectionText className="md:order-2 md:pl-8">
          <SectionTextHeading>Groups Similar Photos and Auto Picks Best Ones</SectionTextHeading>
          <SectionTextSubtitle className="text-gray-500">User Configured Automation Rules and Face Quality of all Important People is considered while Auto Picking Photos in a Group.</SectionTextSubtitle>
          <SectionTextSubtitle className="text-gray-500">As this is a subjective choice you can Manually Flag Photos as Picked, Unflagged & Rejected. Rejected photos are never Auto Picked.</SectionTextSubtitle>
          <LearnMore to="/help/cullai/user-guide/auto-pick"></LearnMore>
        </SectionText>

        <SectionImage className="md:order-1">
          <Video src="p/cullai/group-and-auto-pick.mp4"></Video>
        </SectionImage>

      </Section>

      <Section alternate>

        <SectionText className="md:order-1 md:pr-8">
          <SectionTextHeading>Adjust Grouping Sensitivity</SectionTextHeading>
          <SectionTextSubtitle className="text-gray-500">You can adjust Grouping Sensitivity and Grouping Time Gap to influence grouping.</SectionTextSubtitle>
          <SectionTextSubtitle className="text-gray-500">Higher sensitivity will group more photos together which could be less similar.</SectionTextSubtitle>
          <LearnMore to="/help/cullai/user-guide/auto-grouping"></LearnMore>
        </SectionText>

        <SectionImage className="md:order-2">
          <Video src="p/cullai/group-sensitivity.mp4"></Video>
        </SectionImage>

      </Section>

      <Section>

        <SectionText className="md:order-2 md:pl-8">
          <SectionTextHeading>Filter Bad Quality Photos</SectionTextHeading>
          <SectionTextSubtitle className="text-gray-500">Filter out Photos based on Ratings and Flags.</SectionTextSubtitle>
          <SectionTextSubtitle className="text-gray-500">Export, Share and Drag out only Filtered or Selected photos.</SectionTextSubtitle>
          <LearnMore to="/help/cullai/user-guide/filters"></LearnMore>
        </SectionText>

        <SectionImage className="md:order-1">
          <Video src="p/cullai/filters.mp4"></Video>
        </SectionImage>

      </Section>

      <Section alternate>

        <SectionText className="md:order-1 md:pr-8">
          <SectionTextHeading>Auto Detects Important People</SectionTextHeading>
          <SectionTextSubtitle className="text-gray-500">CullAi automatically treats people who appear often in photos as more important. You can override this to indicate which people are more important.</SectionTextSubtitle>
          <LearnMore to="/help/cullai/user-guide/important-people"></LearnMore>
        </SectionText>

        <SectionImage className="md:order-2">
          <Video src="p/cullai/people.mp4"></Video>
        </SectionImage>

      </Section>

      <Section>

        <SectionText className="md:order-2 md:pl-8">
          <SectionTextHeading>Edit and Sync Ratings and Labels Metadata</SectionTextHeading>
          <SectionTextSubtitle className="text-gray-500">Supports reading and writing both inbuilt and sidecar Metadata.</SectionTextSubtitle>
          <SectionTextSubtitle className="text-gray-500">Helps Sending Auto Ratings to your Favorite App.</SectionTextSubtitle>
          <LearnMore to="/help/cullai/user-guide/metadata"></LearnMore>
        </SectionText>

        <SectionImage className="md:order-1">
          <Video src="p/cullai/sync-metadata.mp4"></Video>
        </SectionImage>

      </Section>

      <Section alternate>

        <SectionText className="md:order-1 md:pr-8">
          <SectionTextHeading>Drag and Drop</SectionTextHeading>
          <SectionTextSubtitle className="text-gray-500">Drag In and Drag Out Photos to and from Other Apps Like Finder, LightRoom, LightRoom Classic, Apple Photos etc.</SectionTextSubtitle>
          <LearnMore to="/help/cullai/user-guide/export-and-share-photos#drag-n-drop-to-other-apps"></LearnMore>
        </SectionText>

        <SectionImage className="md:order-2">
          <Video src="p/cullai/drag-n-drop.mp4"></Video>
        </SectionImage>

      </Section>

      <Section>

        <FeatureCard icon={faKeyboard}
          title="Shortcuts">
          Keyboard Shortcuts, so you can sprint through all photo groups quickly
        </FeatureCard>

        <FeatureCard icon={faSdCard}
          title="RAW">
          Support for RAW formats supported by host <a href="https://support.apple.com/en-in/HT211241"
            target="_blank">macOS</a> version
        </FeatureCard>

        <FeatureCard icon={faFileUpload}
          title="Export">
          Final selected photos to Folder or to compatible Apple Share Apps and Services
        </FeatureCard>

      </Section>

      <Section nogrid>
        <div className="">
          <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-900 text-center mb-8">Frequently Asked Questions</h2>
          <FaqList>
            {faqarticles.map(faq => (
              <FaqListItem key={faq.slug} faq={faq}></FaqListItem>
            ))}
          </FaqList>
          <div className="text-center max-w-3xl mx-auto flex flex-col mt-6">
            <Link className="mb-3" to="/help/cullai/faqs">
              <span>Check all FAQs</span>
              <FontAwesomeIcon className="ml-2" icon={faArrowRight} size="xs"></FontAwesomeIcon>
            </Link>
            <Link className="" to="/help/cullai">
              <span>Check Help Center</span>
              <FontAwesomeIcon className="ml-2" icon={faArrowRight} size="xs"></FontAwesomeIcon>
            </Link>
          </div>
        </div>
      </Section>

      <Section nogrid>
        <h2 className="text-xl font-extrabold text-gray-800 text-center mb-8">Featured In</h2>
        <div className="flex items-center justify-center mx-auto text-3xl sm:text-4xl mt-6">
          <div className="pr-3 mt-1">
            <a target="_blank"
              href="https://petapixel.com/2020/12/11/cullai-is-a-free-artificial-intelligence-culling-tool-for-the-mac/">
              <div className="font-extrabold"><span className="text-gray-900">Peta</span><span className="text-blue-500">Pixel</span>
              </div>
            </a>
          </div>
          <div className="pl-3">
            <a target="_blank"
              href="https://fstoppers.com/originals/cullai-mac-good-idea-has-way-go-538305">
              <img className="max-h-12"
                src="https://media.fstoppers.com/sites/all/themes/fstop_wide/images/fs-logo.png" 
                alt="Fstoppers logo" title="Link to Fstoppers article - Cullai for Mac Is a Good Idea but Has a Way To Go"/>
            </a>
          </div>
        </div>
      </Section>

      <Section>
        <h2 className="md:col-span-12 text-xl font-extrabold text-gray-800 text-center">Reviews</h2>
        <ProductReview className="lg:col-start-2 lg:col-span-5"
          rating={5}
          title="Its not what you think it is, its better"
          body="The real problem with this software, is that theres never been anything like it before. This is no
          rival for other like Adobre Bridge or Photomechanic, and it doesnt need to be one."
          fromName="Vega CosPhoto"
          fromSource="Apple App Store Mexico"
          fromLink="https://apps.apple.com/mx/app/cullai/id1534000261"
        ></ProductReview>

        <ProductReview className="lg:col-span-5"
          rating={4}
          title='Best Cull "IA" app I have yet tried'
          body="Does a very good job of selecting the most valuable photo in a folder. This is the core technology that
          makes it a promising app. Developer has already noted valuable future features. Can't wait."
          fromName="LarsMacmini"
          fromSource="Apple App Store United States"
          fromLink="https://apps.apple.com/us/app/cullai/id1534000261"
        ></ProductReview>

      </Section>

      <Section id="pricing" nogrid>
        <div className="max-w-7xl mx-auto">

          <div className="rounded-t-md bg-gray-800 border border-gray-800 py-8 px-4 md:px-6">
            <h2 className="text-xl font-extrabold text-gray-300 text-center mb-4">Pricing</h2>
            <p className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-gray-50 text-center mb-12">The right pricing for you, whoever you are</p>

            <div className="grid md:grid-cols-2 gap-6 max-w-4xl mx-auto">

              <div className="">
                <div className="rounded-t-md bg-white py-8 px-6">
                  <div className="inline-block rounded-full py-1 px-3 bg-blue-50 text-blue-600 text-sm uppercase font-extrabold mb-4">Hobbyist</div>
                  <h3 className="text-gray-900 text-6xl font-extrabold mb-3">Free</h3>
                  <p>CullAi <b>Lite</b> version for Hobbyist Photographers.</p>
                </div>
                <div className="rounded-b-md bg-gray-50 text-gray-600 py-8 px-6">
                  <ul className="space-y-3">
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Everything in Standard version, limited to <span className="bg-blue-50 text-blue-600 text-sm uppercase font-extrabold">50 Photos</span> per photoshoot.
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      You can upgrade to Standard version using secure one time Apple In App Purchase.
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      You can take <span className="bg-blue-50 text-blue-600 text-sm uppercase font-extrabold">14 Day Free Trial</span> of Standard version before purchasing it, <span className="bg-blue-50 text-blue-600 text-sm uppercase font-extrabold">No Credit Card required</span>.
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Every update to our App is reviewed by Apple for your security and privacy.
                    </li>
                  </ul>
                  <div className="mx-auto mt-8">
                    <AppStoreButton 
                      alt="Download CullAi on Apple App Store button" title="Download CullAi on Apple App Store button" 
                      product="cullai" location="product_page_end" 
                      href="https://apps.apple.com/app/cullai/id1534000261?mt=12&amp;itsct=apps_box&amp;itscg=30200">
                    </AppStoreButton>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="rounded-t-md bg-white py-8 px-6">
                  <div className="inline-block rounded-full py-1 px-3 bg-blue-50 text-blue-600 text-sm uppercase font-extrabold mb-4">Professional</div>
                  <h3 className="text-gray-900 text-2xl mb-3">
                    {/* <span className="line-through mr-1">$99</span> */}
                    <span className="text-6xl font-extrabold">$19</span>
                    <span className="">.99</span>
                    <span className="text-xs text-gray-500 ml-2">one-time</span>
                    {/* <span className="flex flex-col text-xs text-gray-500 ml-1">
                    <span>one-time</span>
                    <span>purchase</span>
                  </span> */}
                  </h3>
                  <p>CullAi <b>Standard</b> version for Professional Photographers.</p>
                </div>
                <div className="rounded-b-md bg-gray-50 text-gray-600 py-8 px-6">
                  <ul className="space-y-3">
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Fast previews of RAW photos.
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Star Ratings, Color Lables and Pick Flags.
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Auto Rate, Auto Group, Auto Pick, Faces Display and Important People AI features.
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Export, Drag-n-Drop and Share to Apps like Lightroom.
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      Inbuilt and Sidecar Metadata updates and syncing.
                    </li>
                    <li><FontAwesomeIcon className="mr-2 text-green-500" icon={faCheck}></FontAwesomeIcon>
                      <span className="bg-blue-50 text-blue-600 text-sm uppercase font-extrabold">Unlimited Photos</span> per photoshoot.
                    </li>
                  </ul>
                  <div className="mx-auto mt-8">
                    <AppStoreButton 
                      alt="Download CullAi on Apple App Store button" title="Download CullAi on Apple App Store button" 
                      product="cullai" location="product_page_end" 
                      href="https://apps.apple.com/app/cullai/id1534000261?mt=12&amp;itsct=apps_box&amp;itscg=30200">
                    </AppStoreButton>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="rounded-b-md bg-gray-50 border border-gray-300 py-8 px-4 md:px-6">

            <ul className="space-y-3 text-sm">
              <li>
                * This price is for Apple US Store and actual price in your native currency will be as per Apple In App Purchase price, read more at FAQ for <Link to="/help/cullai/faqs/how-much-cullai-costs-and-how-payment-is-done">Payments</Link>.
              </li>
            </ul>

          </div>

        </div>

      </Section>

      {/* <Section>
        <div className="md:col-span-4 text-center">
          <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-900">Mac?</h2>
          <AppStoreButton product="cullai" location="product_page_end" href="https://apps.apple.com/app/cullai/id1534000261?mt=12&amp;itsct=apps_box&amp;itscg=30200"></AppStoreButton>
        </div>
        <div className="md:col-span-8 text-center">
          <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-900">Windows / iOS / Android?</h2>
          <p className="text-base lg:text-xl text-gray-500 mt-3">We are coming soon on other platforms, get notified when we do</p>
          <div className="flex items-center justify-center space-x-4 mt-4">
            <input type="email" className="flex-1 h-14 max-w-xs px-4 focus:ring-blue-500 block border-2 border-gray-400 rounded-md text-base md:text-lg lg:text-xl text-gray-900" placeholder="your@email.com" />
            <button className="btn btn-lg btn-blue">Notify</button>
          </div>
        </div>
      </Section> */}

      <Section id="updates" nogrid className="related-posts container medium">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-900 text-center mb-4">Latest Updates</h2>
          <ArticleFeed>
            {updatearticles.map(article => {
              enhanceBlogArticle(article)
              return (
                <ArticleFeedItem key={article.id} article={article}></ArticleFeedItem>
              )
            })}
          </ArticleFeed>
        </div>
      </Section>

      <Section id="roadmap" className="" nogrid>
        <div className="text-center">
          <h2 className="text-3xl lg:text-4xl font-extrabold text-gray-900 mb-4">Together we can make Culling Photos fast</h2>
          <p className="text-xl lg:text-2xl mb-16">Here is how you can help</p>

          <div ref={roadmapContainer} className="grid md:grid-cols-2 lg:grid-cols-3 gap-16 md:gap-10 my-10">

            <div className="md:col-span-2 lg:col-span-1 md:max-w-lg mx-auto">
              <h3 className="text-xl lg:text-2xl text-gray-900 font-extrabold mb-4">1. Questions, Feedback, New Feature Ideas?</h3>
              <p className="text-lg mb-4">Please write us at <a href="mailto:cullai-support@bookmyshoot.net">cullai-feedback@dopeai.tech</a>.
                For feature requests, we will create a feature on our Public Roadmap where comunity can upvote and add comments.
              </p>
              <p className="text-lg mb-4">For support requests please use our <Link to="/help/cullai">Help Center</Link>.</p>
            </div>

            <div className="max-w-lg mx-auto">
              <h3 className="text-xl lg:text-2xl text-gray-900 font-extrabold mb-4">2. Check out CullAi Public Roadmap on Trello</h3>
              <div style={{ maxWidth: '248px' }} className="mx-auto text-lg">
                <blockquote className="trello-board-compact">
                  <a href="https://trello.com/b/ykgWmVOy/cullai-public-roadmap">CullAi Public Roadmap</a>
                </blockquote>
              </div>
            </div>
            <div className="max-w-lg mx-auto">
              <h3 className="text-xl lg:text-2xl text-gray-900 font-extrabold mb-4">3. Upvote and Comment on Upcoming Features</h3>
              <div style={{ maxWidth: '316px' }} className="mx-auto text-lg">
                <blockquote className="trello-card">
                  <a href="https://trello.com/c/m5VqCbGn/129-grid-view-of-similar-photos-for-faster-comparison">Grid View of Similar Photos</a>
                </blockquote>
              </div>
            </div>

          </div>
        </div>
      </Section>

    </Layout>

  )
}

export default CullAiProductPage

export const pageQuery = graphql`
query CullAiProductPageQuery {
  faqs: allMdx(
    filter: {
      frontmatter: {product: {eq: "cullai"}, category: {eq: "FAQs"}},
      fields: { source: {eq: "help"} }
    }
    sort: {fields: frontmatter___sn, order: ASC},
    limit: 6
  ) {
    faqarticles: nodes {
      id
      excerpt(pruneLength: 128)
      timeToRead
      frontmatter {
        product
        category
        title
        date(formatString: "MMM DD, YYYY")
      }
      fields {
        source
      }
    }
  }
  updates: allMdx(
    filter: {
      frontmatter: {product: {eq: "cullai"}, category: {eq: "Updates"}},
      fields: { source: {eq: "blog"} }
    }
    sort: {fields: frontmatter___date, order: DESC}
    limit: 3
  ) {
    updatearticles: nodes {
      id
      excerpt(pruneLength: 128)
      timeToRead
      frontmatter {
        product
        category
        title
        date(formatString: "MMM DD, YYYY")
      }
      fields {
        source
      }
    }
  }
}
`
