import React from "react"
import { StarRating } from "./starrating";

export function ProductReview({ rating, title, body, fromName, fromSource, fromLink, className }) {
  return (
    <div className={`md:col-span-6 text-center max-w-md mx-auto ${className || ''}`}>
      <figure>
        <blockquote cite={fromLink}>
          <div className="flex items-center justify-center">
            <StarRating rating={rating} className="text-2xl text-yellow-500" />
          </div>
          <h3 className="text-xl lg:text-2xl font-extrabold text-gray-900 mt-4">{title}</h3>
          <p className="bg-gray-100 p-4 rounded text-lg text-gray-600 mt-2">"{body}"</p>
        </blockquote>
        <figcaption className="text-gray-600 text-lg mt-2">—by {fromName}, <cite>{fromSource}</cite></figcaption>
      </figure>
    </div>
  );
}